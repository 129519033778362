.active {
  background-color: #d9d9d9;
  font-weight: bolder;
}

.pageItem {
  cursor: pointer;
  border: solid 1px #d9d9d9;
  padding: 4px 8px;
  font-size: 16px;
  list-style-type: none;
}

.container {
  display: flex;
  align-self: flex-end;
  gap: 2px;
  margin: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrapper {
  align-self: flex-end;
  max-width: 300px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
